import React from 'react';
import { RedocStandalone } from 'redoc';
import { otisTheme } from './redoc-theme';

export const App: React.FunctionComponent = () => {
  return (
    <>
      <RedocStandalone
        specUrl={process.env.REACT_APP_OPEN_API_SPEC_URL}
        options={{
          theme: otisTheme,
          hideLoading: true,
        }}
      />
    </>
  );
}

